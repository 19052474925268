import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Button from '../components/UI/ButtonPrimary';
import HeaderPath from '../img/header-path-gray.svg';
import NotFound from '../img/not-found.png';
import { breakpointLarge } from '../styles/breakpoints';

const breakpointSmall = '600px';

const NotFoundStyled = styled.div`
  position: relative;
`;
const Header = styled.div`
  background-color: ${props => props.theme.yellow};
  background-image: url(${HeaderPath});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding: 4rem 3rem 5rem;
  font-size: 2rem;
  color: ${props => props.theme.blueDark};
  @media (min-width: ${breakpointSmall}) {
    padding: 7rem 3rem 2rem;
    background-color: ${props => props.theme.grayLight};
  }
`;
const HeaderContent = styled.div`
  width: 70%;
  @media (min-width: ${breakpointSmall}) {
    padding-left: 10%;
  }
  @media (min-width: ${breakpointLarge}) {
    padding-left: 30%;
  }
`;
const ImageContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 35%;
  max-width: 8rem;
  z-index: 0;
  @media (min-width: ${breakpointSmall}) {
    right: 20%;
  }
  @media (min-width: ${breakpointLarge}) {
    max-width: 10rem;
    right: 30%;
  }
`;
const Img = styled.img`
  width: 100%;
  height: auto;
`;
const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  z-index: 100;
  @media (min-width: ${breakpointSmall}) {
    justify-content: flex-start;
    padding-left: 30%;
  }
`;
const ButtonStyled = styled(Button)`
  padding-left: 4rem !important;
  padding-right: 4rem !important;
`;

const NotFoundPage = () => (
  <Layout>
    <NotFoundStyled>
      <Header>
        <HeaderContent>
          <div>¡Uuuups!</div>
          <div>Algo salió mal</div>
        </HeaderContent>
      </Header>
      <Content>
        <ButtonStyled href="/">Volver</ButtonStyled>
      </Content>
      <ImageContainer>
        <Img src={NotFound} alt="" />
      </ImageContainer>
    </NotFoundStyled>
  </Layout>
);

export default NotFoundPage;
