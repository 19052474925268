import styled from 'styled-components';
import Button from './Button';

const ButtonPrimary = styled(Button)`
  color: #fff;
  background-color: ${p => p.theme.red};
  border-color: ${p => p.theme.red};
`;



export default ButtonPrimary;
